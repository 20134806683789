<template>
  <div>
    <DesktopLayout v-if="!isMobile" @goBack="back" @goNext="next" @save="save" :isLoading="isLoading" :page="page" :totalPages="totalPages" :wizardSteps="wizardSteps" :nextDisabled="nextDisabled" :saveDisabled="saveDisabled">
      <WizardPage1 v-if="page === 1" v-model="value" />
      <WizardPage2 v-if="page === 2" v-model="value" />
      <WizardPage3 v-if="page === 3" v-model="value" v-model:terms="terms" @goToDonorPage="page = 1" @goToPaymentPage="page = 2" />
    </DesktopLayout>
    <MobileLayout v-else @goBack="back" @goNext="next" @save="save" :isLoading="isLoading" :page="page" :totalPages="totalPages" :wizardSteps="wizardSteps" :nextDisabled="nextDisabled" :saveDisabled="saveDisabled">
      <WizardPage1 v-if="page === 1" v-model="value" />
      <WizardPage2 v-if="page === 2" v-model="value" />
      <WizardPage3 v-if="page === 3" v-model="value" v-model:terms="terms" @goToDonorPage="page = 1" @goToPaymentPage="page = 2" />
    </MobileLayout>
    <transition name="fade">
      <TurnstilePopup v-if="isTurnstile" @close="closeTurnstile" @verify="verifyTurnstile"/>
    </transition>
    <transition name="fade">
      <CompletePopup v-if="isComplete" />
    </transition>
    <transition name="fade">
      <Popup v-if="tooSmall" @close="closeTooSmall">
        <div class="row mt-4 justify-content-center text-center">
          <div class="col-7 col-md-6 text-center">
            <ExclamationAnimation />
          </div>
          <div class="col-12 mb-3 text-center medium font23 green-text">
            Error
          </div>
          <div class="col-10 mb-3 bigger_font text-center medium green-text">
            The minimum donation total is <b>R5.00</b>
          </div>
          <div class="col-10 mb-3 text-center font15">
            Please increase your donation so the total is at least <b>R5.00</b>
          </div>
        </div>
      </Popup>
    </transition>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    DesktopLayout: defineAsyncComponent(() => import('../components/DesktopLayout.vue')),
    MobileLayout: defineAsyncComponent(() => import('../components/MobileLayout.vue')),
    WizardPage1: defineAsyncComponent(() => import('./wizard/WizardPage1.vue')),
    WizardPage2: defineAsyncComponent(() => import('./wizard/WizardPage2.vue')),
    WizardPage3: defineAsyncComponent(() => import('./wizard/WizardPage3.vue')),
    CompletePopup: defineAsyncComponent(() => import('./CompletePopup.vue')),
    ExclamationAnimation: defineAsyncComponent(() => import('@/components/animations/ExclamationAnimation.vue')),
    Popup: defineAsyncComponent(() => import('@/views/Popup.vue')),
    TurnstilePopup: defineAsyncComponent(() => import('@/views/TurnstilePopup.vue'))

  },
  props: {
    modelValue: {
      type: Object,
      required: true
    }
  },
  emits: ['update:modelValue', 'save', 'hideWizard'],
  name: 'Recurring Donation Wizard',
  data () {
    return {
      page: 1,
      totalPages: 3,
      wizardSteps: [
        {
          stepNumber: 1,
          title: 'Donor<br/>Details'
        },
        {
          stepNumber: 2,
          title: 'Payment<br/>Method'
        },
        {
          stepNumber: 3,
          title: 'Complete<br/>Setup'
        }
      ],
      isLoading: false,
      terms: false,
      isTurnstile: false,
      turnstileReturn: null,
      tooSmall: false,
      isComplete: false
    }
  },
  computed: {
    ...mapGetters([
      'isApp', 'isMobile', 'donateAs'
    ]),
    value: {
      get () {
        return this.modelValue
      },
      set (value) {
        this.$emit('update:modelValue', value)
      }
    },
    nextDisabled () {
      if (this.page === 1 && !this.donateAs) {
        return true
      } else if (this.page === 2 && (!this.value.recurringDonationType || this.value.recurringDonationType === '')) {
        return true
      } else if (this.page === 2 && (!this.value.startDate || !this.value.debitOrderDay)) {
        return true
      } else if (this.page === 2 && this.value.recurringDonationType === 'DEBIT_ORDER' && (!this.value.donorBankAccount.bankName || !this.value.donorBankAccount.accountHolderName || !this.value.donorBankAccount.branchCode || !this.value.donorBankAccount.accountNumber)) {
        return true
      } else if (this.page === 2 && this.value.recurringDonationType === 'CREDIT_CARD' && (!this.value.frequency || this.value.frequency === '')) {
        return true
      } else {
        return false
      }
    },
    saveDisabled () {
      if (!this.terms) {
        return true
      } else {
        return false
      }
    },
    theTotal () {
      let total = 0
      if (this.value.transactionDTO.donationDtoList.length > 0) {
        this.value.transactionDTO.donationDtoList.forEach(donation => {
          total += Number(donation.amount)
        })
      }
      return total
    }
  },
  methods: {
    ...mapActions(['saveRecurringDonation', 'initiatePayfast']),
    back () {
      console.log('BACK', this.page, this.page - 1)
      if (this.page === 1) {
        this.$emit('hideWizard')
      } else if (this.page === 2 && this.value.recurringDonationType !== '') {
        this.value.recurringDonationType = ''
      } else {
        this.page--
      }
    },
    next () {
      console.log('NEXT', this.page, this.page + 1)
      this.page++
    },
    async save () {
      this.isLoading = true
      if (this.value.recurringDonationType === 'CREDIT_CARD') {
        await this.checkTurnstile()
      } else {
        await this.saveDonation()
      }
    },
    closeTurnstile () {
      this.turnstileReturn = null
      this.isTurnstile = false
      this.isLoading = false
    },
    verifyTurnstile (val) {
      this.turnstileReturn = val
      this.isTurnstile = false
      if (this.turnstileReturn) {
        this.saveDonation()
      }
    },
    checkTurnstile () {
      if (this.value.recurringDonationType === 'CREDIT_CARD' && this.theTotal < 5) {
        this.tooSmall = true
      } else {
        this.isTurnstile = true
      }
    },
    async saveDonation () {
      const ret = await this.saveRecurringDonation(this.value)
      if (ret) {
        console.log('RET', ret)
        if (ret.data.recurringType === 'CREDIT_CARD') {
          console.log('HEREEEE', ret.data.subscriptionReference, this.turnstileReturn)
          const postData = {
            transactionRef: ret.data.subscriptionReference,
            recurringDonationID: ret.data.recurringDonationID,
            token: this.turnstileReturn
          }
          console.log('HEREEEE postData', postData)
          const returnDetails = await this.initiatePayfast(postData)
          if (returnDetails) {
            const myData = []
            // Merchant details
            myData.merchant_id = parseInt(returnDetails.merchant_id)
            myData.merchant_key = returnDetails.merchant_key
            myData.return_url = returnDetails.return_url
            myData.cancel_url = returnDetails.cancel_url
            myData.notify_url = returnDetails.notify_url
            // Transaction details
            myData.m_payment_id = returnDetails.m_payment_id
            myData.amount = parseFloat(returnDetails.amount).toFixed(2)
            myData.item_name = returnDetails.item_name
            if (returnDetails.email_address) {
              myData.email_address = returnDetails.email_address
            }
            myData.email_confirmation = returnDetails.email_confirmation
            myData.payment_method = returnDetails.payment_method
            myData.subscription_type = returnDetails.subscription_type
            myData.billing_date = returnDetails.billing_date
            myData.recurring_amount = returnDetails.recurring_amount
            myData.frequency = returnDetails.frequency
            myData.cycles = returnDetails.cycles

            // Generate signature
            myData.signature = returnDetails.signature
            var form = document.createElement('form')
            form.setAttribute('method', 'post')
            form.setAttribute('action', returnDetails.redirect.url)
            for (const key in myData) {
              if (Object.prototype.hasOwnProperty.call(myData, key)) {
                const val = myData[key]
                if (val !== '') {
                  var ele = document.createElement('input')
                  ele.setAttribute('value', val)
                  ele.setAttribute('name', key)
                  // ele.value = val
                  // ele.name = key
                  form.appendChild(ele)
                }
              }
            }
            document.body.appendChild(form)
            form.submit()
            this.isLoading = false
          }
        } else {
          this.isLoading = false
          this.isComplete = true
        }
      }
      this.isLoading = false
    },
    closeTooSmall () {
      this.tooSmall = false
      this.isLoading = false
    }
  }
}

</script>
